@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Grgrifter;
  src: url(/public/fonts/grifterbold.otf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: myFont;
  src: url(/public/fonts/Inter.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: customFont;
  src: url(/public/fonts/Helvetica.ttf);
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(/public/fonts/Poppins-Medium.ttf);
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(/public/fonts/Poppins-Regular.ttf);
  font-weight: 100;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  border: none;
  font-family: myFont, Poppins, sans-serif;
}

:root {
  --titleColor: #fff;
  --backgroungColor: #10393b;
  /* --backgroungColor: #e5e9e3; */
  --cta: #10393b;
  --ctaText: #ffffff;

  --warningColor: #ee5529;
  --footerColor: #388c93;

  --borderRadius: 2em;
  --white: #ffffff;
  --yellow: #f5c338;
  --purple: #b32580;
  --light-background: #e8ecef;

  --green: #47da38;
  --secondaryColor: #e8ecef;
}

body {
  background-color: var(--backgroungColor);
  color: var(--titleColor);
}

.pt-1 {
  padding-top: 0.5em;
}

.hidden {
  display: none;
}

.file-icon {
  width: 1em;
  margin: 0 1em 0 0;
}

.flexRow {
  display: flex;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexCol-nc {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.primaryButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--cta);
  padding: 1em;
  border-radius: 1em;
  width: inherit;
  position: fixed;
  bottom: 0;
  align-items: stretch;
  margin-bottom: 1.7em;
  z-index: +5;
}

.primaryButton-login {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  /* background-color: var(--cta); */
  /* width: 15em; */
  padding: 1em;
  border-radius: 1em;
  /* width: 80%; */
  text-align: center;
  cursor: pointer;
}

.buttonIcon {
  width: 0.5em;
}

.primaryButtonText {
  padding: 0 0.8em;
  font-size: 1.4em;
  font-family: Poppins, sans-serif;
  color: var(--ctaText);
}

.loginButtonText {
  padding: 0 0.8em;
  /* font-size: 1.7em; */
  font-family: Poppins, sans-serif;
  color: var(--ctaText);
}

.pageTitle {
  /* margin: 0 0 80vh; */
  font-size: 4em;
  font-weight: 400;
}

.pageTitle-normal {
  font-size: 4em;
  font-weight: 400;
}

.form-row {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.form-element {
  width: -webkit-fill-available;
}

.form-input-element {
  width: -webkit-fill-available;
  padding: 1.1em 1.2em;
  border-radius: 1em;
  font-size: 1.1em;
  background-color: var(--secondaryColor);
  border: 2px solid var(--cta);
  color: var(--cta);
  cursor: pointer;
}

.form-input-element-profile-pic {
  width: 13em;
  height: 5em;
  padding: 1.2em;
  border-radius: 10em;
  font-size: 1.1em;
  background-color: var(--secondaryColor);
  border: 2px solid var(--cta);
  color: var(--cta);
  cursor: pointer;
}

.form-input-element-profile-pic-hide {
  border-radius: 10em;
  font-size: 1.1em;
  background-color: #ffffff00;
  border: 2px solid #ffffff00;
  color: var(--cta);
  cursor: pointer;
}

.form-label {
  font-size: 1.6em;
}

.form-input-el {
  width: -webkit-fill-available;
  margin: 1.5em auto;
}

.form-input {
  width: -webkit-fill-available;
  margin: 1.5em 0 0.6em 0;
}

.form-field-error {
  /* border: 1px solid red; */
  /* box-shadow: inset 0px 0px 0px 2px var(--warningColor); */
  border: 2px solid var(--warningColor);
}

.loginFormContainer {
  margin: 5em auto 2em;
  width: -webkit-fill-available;
}

/* Dashboard */
.dashboard-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3em 3.5em;
  margin: 4vw 2vw 0 0;
  width: 39vw;
  border-radius: var(--borderRadius);
}

.floating-button-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: fixed;
  bottom: 1em;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  /* width: 6em; */
  /* z-index: +5; */
}

.floating-button-container-right {
  /* right: 0em; */
}

.floating-button-container-left {
  /* left: 0em; */
}

.floating-button-icon {
  width: 4em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em;
  /* background-color: var(--cta); */
  border-radius: 5em;
  border: 1px solid #ffffff4f;
}
.nav-icon {
  width: 100%;
}

.main-form-container {
  width: -webkit-fill-available;
  /* height: 90vh; */
  background-color: var(--white);
  margin: 2em 1em;
  padding: 1.5em;
  border-radius: 1em;
}

.customer-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.9em;
  height: 30em;
  background-color: var(--light-background);
  border-radius: 1em;
}

.button-icons {
  width: 2.2em;
}

.button-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-container {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 3em 0 0 0;
  padding: 1em;
}

.button-text-regular {
  padding: 0 0.8em;
  font-size: 1.7em;
  font-family: Poppins, sans-serif;
  color: var(--white);
  font-weight: 600;
}

.primaryButton-regular {
  background-color: var(--cta);
  padding: 1.3em 1em;
  border-radius: 0.7em;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .reports-container {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .dashboard-cards {
    padding: 3em 3.5em;
    margin: 2vw auto;
    width: 80vw;
  }

  .reports-card-value,
  .reports-card-value-bright {
    font-size: 2.8em;
  }

  .reports-card-label,
  .reports-card-label-bright {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 400px) {
  .button {
    font-size: 0.7em;
  }
}

.loading-anim {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 1em;
  min-width: 5em;
  max-width: 18vw;
}

.mainFrame {
  margin: 2em auto 7em;
  width: 92vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
}

/* Header styles */
.profile {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.7em 0 0 0;
  height: 5em;
  padding: 0.7em 0.2em;
  cursor: pointer;
}

.profile:hover,
.profile:hover .logout {
  background-color: var(--warningColor);
  border-radius: 0.9em;
  color: var(--white);
}

.profile-avatar {
  width: 2.5em;
  height: 2.5em;
  border-radius: 5em;
  border: 3px solid var(--white);
}

.logout {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8em;
  color: var(--cta);
  margin-top: 0em;
  padding: 0.4em 0.8em;
  border: 2px solid rgba(255, 255, 255, 0);
}

.logout:hover {
  color: var(--white);
  border: 2px solid var(--warningColor);
  background-color: var(--warningColor);
  border-radius: 0.7em;
}

.header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: column;
  /* background-color: #83aff1; */
  margin-bottom: 2.5em;
}
.logo {
  width: 1.5em;
  margin-right: 0.5em;
}

.logo-container {
  display: flex;
  align-items: center;
}

.greatings-container {
  margin-top: 0.8em;
}

.user-name {
  margin-top: 0.1em;
  font-weight: 300;
}

.success-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  text-align: center;
}

.success-message > .message-icon,
.message-body {
  margin: 0.5em;
}

.success-message > .message-title {
  padding: 0 0 0.3em 0;
}

/* Dashboard styles */
.card {
  /* background: var(--white); */
  border-radius: 1.2em;
  /* border: 1px solid rgba(30, 30, 30, 0.25); */
  padding: 1.5em 1em;
}

.card-title {
  display: flex;
  font-family: Grgrifter, sans-serif;
}

.pb-1 {
  padding-bottom: 0.3em;
}
.card-title-contianer {
  display: flex;
  align-items: center;
}

.title-contianer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.env-container {
  display: flex;
  align-items: center;
  margin: 0.5em auto;
}

.option-tag {
  background-color: var(--white);
  border: 1px solid var(--cta);
  color: var(--cta);

  padding: 0.4em 1.3em;
  margin-right: 0.5em;
  border-radius: 1.2em;
  font-size: 1em;

  cursor: pointer;
}

.option-tag:hover {
  background-color: var(--cta);
  color: var(--white);
}

.platform-option-tag {
  background-color: var(--white);
  border: 1px solid var(--cta);
  color: var(--cta);

  padding: 0.4em 1.3em;
  margin-right: 0.5em;
  border-radius: 1.2em;
  font-size: 1em;

  cursor: pointer;
}

.media-result {
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  margin: 0.2rm;
}

.platform-option-tag:hover {
  background-color: var(--cta);
  color: var(--white);
}

.selected-tag {
  background-color: var(--cta);
  color: var(--white);
}

.secondary-card {
  background-color: var(--secondaryColor);
  border-radius: 1.2em;
  padding: 0.5em 1.2em 1.5em;
  margin: 2em auto 0;
  color: var(--cta);
  width: -webkit-fill-available;
}

.article-title {
  font-size: 2em;
  font-family: customFont, sans-serif;
}

.article-env-tag {
  background-color: var(--green);
  color: var(--white);
  padding: 0.4em 1.4em;
  border-radius: 1em;
  margin: 1em;
  font-size: 0.9em;
  letter-spacing: 2px;
}

.env-test {
  background-color: var(--yellow);
}

.article-header {
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.ticket-article-header {
  align-content: center;
  justify-content: space-between;
  /* align-items: center; */
}

.ticket-article-header-wmt {
  align-content: center;
  justify-content: space-between;
  margin-top: 1em;
  /* align-items: center; */
}

.media-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 20em;
  border-radius: 1em;
}

.scroll-x {
  overflow-x: scroll;
}

.ticket-number {
  padding: 0 0.5em 0 0;
  font-weight: 500;
  cursor: pointer;
}

.ticket-media {
  /* height: 12em; */
  width: 12em;

  margin: 0.2em 1em 0 0;
  border-radius: 1em;
}

.ticket-media-video {
  width: 12em;
  margin: 0.2em 1em 0 0;
  border-radius: 1em;
}

.ticket-status-badge {
  display: flex;
  color: var(--white);
  padding: 0.8em 1.4em;
  border-radius: 1em;
  margin: 1em auto;
  font-size: 0.9em;
  letter-spacing: 2px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.green-bg {
  background-color: var(--green);
}

.red-bg {
  background-color: var(--purple);
}

.yellow-bg {
  background-color: var(--yellow);
}

.time {
  font-family: Poppins, sans-serif;
  padding: 0.5em 0;
}

.priority-badge {
  padding: 0.5em 1.2em;
  /* background-color: #388c93; */
}

.article-section {
  padding: 1em 0 0 0;
}

.section-title {
  padding: 0 0 0.2em 0;
}

.more-info {
  z-index: +2;
  padding: 1.5em 1.5em 0;
  position: relative;
  top: -1.5em;
  background: linear-gradient(
    180deg,
    rgba(232, 236, 239, 0.5) 0%,
    #e8ecef 53.12%
  );
  cursor: pointer;
  /* text-decoration: underline; */
}

.button-containers {
  justify-content: space-between;
  align-items: center;
}

.copy-icon {
  width: 1.2em;
}

.button {
  /* margin: 1.5em auto 0; */
  display: flex;
  background-color: var(--cta);
  color: var(--ctaText);
  padding: 0.81em 1em;
  border-radius: 0.8em;
  align-items: center;
  justify-content: center;
}

.width100 {
  width: 100%;
}

.primary-button {
  margin: 3em 0 0 0;
  width: -webkit-fill-available;
  background-color: var(--cta);
  color: var(--ctaText);
  padding: 1em 1em;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  cursor: pointer;
}

.disabled {
  background-color: var(--cta);
  opacity: 0.5;
  pointer-events: none;
}

.button-icon {
  margin: 0 0.2em 0 0;
  width: 1.2em;
}

/* Ticket Updates */

.card-title-secondary {
  /* font-family: Grgrifter, sans-serif; */
  font-size: 1.5em;
}

.updates-container {
  margin: 1em auto;
}

.update-title {
  font-size: 1em;
  color: var(--cta);
}

.author-name {
  font-size: 0.9em;
  color: var(--cta);
}

.update-container {
  margin: 0em auto;
  display: flex;
  flex-direction: column;
}
.update-body {
  border-radius: 0.8em;
  border: 1.5px solid var(--cta);
  padding: 1.5em 1em 0.9em;
}

.update-timestamp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 1em;
  background: var(--white);
  border-radius: 8em;
  border: 1.5px solid var(--cta);
  padding: 0.3em 0.8em;
  margin: auto;
}

.timestamp {
  padding-left: 0.3em;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.8em;
  color: var(--cta);
}

.author-container {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.author-avatar,
.default-avatar {
  width: 1.7em;
  height: 1.7em;
  /* border: 1px solid #2f3e46b8; */
  border-radius: 8em;
  margin: 0 0.5em 0 0;
}

.default-avatar {
  background-color: #86fad6;
}

.mt-1 {
  margin-top: 2em;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
.goog-te-gadget {
  color: gainsboro;
  font-size: 0px;
}
.goog-te-gadget img {
  display: none !important;
}

.goog-te-combo {
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 2em;
}

.google_translate_element {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0.5em 0; */
}

#goog-gt-tt,
#goog-gt-vt {
  visibility: hidden;
  display: none;
}

/* Hide the first two <td> elements with width="1" inside the Google Translate iframe */
iframe.skiptranslate td[width="1"]:nth-of-type(1),
iframe.skiptranslate td[width="1"]:nth-of-type(2) {
  display: none !important;
}

iframe.skiptranslate td[width="1"] {
  display: none !important;
}
